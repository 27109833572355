import "./particles.scss"

import ParticlesJS from "react-particles-js"
import React from "react"

const Particles = () => (
  <ParticlesJS
    params={{
      fpsLimit: 60,
      particles: {
        number: {
          value: 40,
          density: {
            enable: true,
            value_area: 500
          }
        },
        line_linked: {
          enable: true,
          opacity: 0.15,
          color: "#f62594",
          width: 10
        },
        move: {
          speed: 2
        },
        size: {
          value: 10
        },
        opacity: {
          anim: {
            enable: true,
            speed: 1,
            opacity_min: 0.05
          }
        }
      },
      interactivity: {
        events: {
          onclick: {
            enable: true,
            mode: "push"
          },
          onhover: {
            enable: true,
            mode: "repulse"
          }
        }
      },
      retina_detect: true
    }}
  />
)

export default Particles
